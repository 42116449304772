

















































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import TaskState from '@/task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';

const parts: ('A' | 'B' | 'C' | 'D')[] = ['A', 'B', 'C', 'D'];
export type TorontoCHMB41HQuiz4Question1Part = (typeof parts)[number];

export type TorontoCHMB41HQuiz4Question1TaskState = TaskState<{
  partAVersion: VariableValueType.IntegerValue;
  partBVersion: VariableValueType.IntegerValue;
  partCVersion: VariableValueType.IntegerValue;
  partDVersion: VariableValueType.IntegerValue;
}>;

export default defineComponent({
  name: 'TorontoCHMB41HQuiz4Question1',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<TorontoCHMB41HQuiz4Question1TaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        A: null as null | string,
        B: null as null | string,
        C: null as null | string,
        D: null as null | string,
      },
      imagePrefix: '/img/assignments/UofT/CHMB41H/quiz-4/question-1/q4q1',
      questionParts: parts,
    };
  },
  computed: {
    seed(): number {
      return this.$inertia.getSeed();
    },
    versions(): Partial<Record<TorontoCHMB41HQuiz4Question1Part, number>> {
      return parts.reduce(
        (acc: Partial<Record<TorontoCHMB41HQuiz4Question1Part, number>>, part) => {
          acc[part] = this.taskState.getValueByName(`part${part}Version`)?.content?.value ?? 1;
          return acc;
        },
        {}
      );
    },
    choices(): string[] {
      return seededShuffle(['A', 'B', 'C', 'D'], this.seed);
    },
    images(): Partial<Record<TorontoCHMB41HQuiz4Question1Part, string>> {
      return parts.reduce(
        (acc: Partial<Record<TorontoCHMB41HQuiz4Question1Part, string>>, part) => {
          acc[part] = `${this.imagePrefix}-part${part}-v${this.versions[part]}.png`;
          return acc;
        },
        {}
      );
    },
    choiceImages(): Partial<Record<TorontoCHMB41HQuiz4Question1Part, string[]>> {
      return parts.reduce(
        (acc: Partial<Record<TorontoCHMB41HQuiz4Question1Part, string[]>>, part) => {
          acc[part] = this.choices.map(
            (choice: string) =>
              `${this.imagePrefix}-part${part}-v${this.versions[part]}-choice${choice}.png`
          );
          return acc;
        },
        {}
      );
    },
  },
});
